import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$t('Territories')))]),_c('zone-territory-create',{attrs:{"value":_vm.value},on:{"created":_vm.onTerritoryCreated},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"depressed":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Add territory'))+" ")])]}}])})],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.territories,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.country",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCountryName(item.country_code))+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('zone-territory-update',{attrs:{"value":item,"zone":_vm.value},on:{"updated":_vm.onTerritoryUpdated},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('button-action',_vm._g(_vm._b({attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit')}},'button-action',attrs,false),on))]}}],null,true)})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(_vm.value.id)?_c('territory-action-delete',{attrs:{"icon":"","zone-id":_vm.value.id,"value":item},on:{"success":function($event){return _vm.onTerritoryDeleted(item.id)},"error":_vm.onDeleteError}}):_c('delete-button',{attrs:{"suppress-confirmation":"","icon":true},on:{"click":function($event){return _vm.onTerritoryDeleted(item.id)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }